<template>
  <div>
    <div id="login_show_box">
      <div class="radioType">
        <div class="radioType_p" :class="type===0?'active' : 'active2'" @click="choiceType(0)">
          <p style="marginTop: -3px">
            <img v-show="type!==0" src="@/assets/forget/phone2.png" alt="">
            <img v-show="type===0" src="@/assets/forget/phone_red2.png" alt="">
          </p>
          <span> 手机号</span>
        </div>
        <div class="radioType_p" :class="type===1?'active' : 'active2'" @click="choiceType(1)">
          <p style="marginTop: -2px">
            <img v-show="type!==1" src="@/assets/forget/user2.png" alt="">
            <img v-show="type===1" src="@/assets/forget/user_red2.png" alt="">
          </p>
          <span> 用户名</span>
        </div>
        <div class="radioType_p" :class="type===2?'active' : 'active2'" @click="choiceType(2)">
          <p>
            <img v-show="type!==2" src="@/assets/forget/email2.png" alt="">
            <img v-show="type===2" src="@/assets/forget/email_red2.png" alt="">
          </p>
          <span> 邮箱</span>
        </div>
      </div>
      <div class="inp-all">
        <input v-if="type===0" v-model.trim="account" class="bor_red" placeholder="请输入您账号绑定的手机号" @blur="testphone">
        <input v-else-if="type===1" v-model.trim="account" class="bor_red" placeholder="请输入您账号的用户名" @blur="testphone">
        <input v-else v-model.trim="account" class="bor_red" placeholder="请输入您账号绑定的邮箱" @blur="testphone">
        <p v-if="isExists!=1" class="ps">{{ text }}</p>
      </div>
      <div>
        <span class="next_btn" @click="next">下一步</span>
      </div>
    </div>
  </div>
</template>
<script>
import '@/styles/weblogin.css'
import { findAccount } from '@/api/forget'
export default {
  props: {
    stepsShow: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      type: 0,
      text: '',
      account: '',
      uid: '',
      isExists: 1, // 账号/手机号/邮箱是否存在  1 存在  0 不存在
      mobile: '',
      email: '',
      is_mobile: '', // 1 已绑定手机 0未绑定
      is_email: '', // 1 已绑定邮箱 0 未绑定邮箱
      info: {}
    }
  },
  created() {
    console.log(this.stepsShow, 'stepsShow')
  },
  mounted() {

  },
  methods: {
    // 切换验证方法
    choiceType(val) {
      if (val === 0) {
        this.type = 0
      } else if (val === 1) {
        this.type = 1
      } else {
        this.type = 2
      }
    },
    testphone(val) {
      console.log(val, 'val')
    },
    // 下一步
    next() {
      const info = {
        type: this.type,
        account: this.account
      }
      findAccount(info).then(res => {
        if (res.errNo !== 0) {
          this.isExists = ''
          this.text = res.msg
          return
        } else {
          var data = res.result
          this.isExists = data.isExists
          if (this.isExists === 1) {
            this.uid = data.uid
            this.mobile = data.mobile
            this.email = data.email
            this.is_mobile = data.is_mobile
            this.is_email = data.is_email
            this.info = data
            this.$emit('next', this.info, this.type)
          } else {
            this.text = '该账号不存在，请确认后再输入'
          }
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
.inp-all{
  width: 340px;
  height: 50px;
  margin: 0 auto 30px;
  .ps{
    color: #ee2e2e;
    width: 340px;
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
  }
}
.bor_red{
  width: 340px;
  height: 50px;
  position: relative;
  float: left;
  margin-bottom: 5px;
  padding-left: 10px;
  border: 1px solid #eee;
  line-height: 50px;
  font-size: 14px;
  color: #333;
}
.next_btn{
  width: 340px;
  height: 50px;
  display: block;
  background: #ee2e2e;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 50px;
  margin: 10px auto 0;
  cursor: pointer;
}
.radioType{
  display: flex;
  justify-content: space-between;
  width: 340px;
  margin: 0 auto 30px;
  height: 40px;
  .radioType_p{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    line-height: 40px;
    background: rgba(153,153,153,0.05);
    font-size: 16px;
    color: #999999;
    font-weight: 500;
    cursor: pointer;
    p{
      margin-right: 3px;
      width: 20px;
      img{
        width: 100%;
        vertical-align: middle;
      }
    }
    span{
      vertical-align: middle;
    }
  }
  .active{
    border-bottom: 3px solid #ee2e2e;
  }
  .active2{
    border-bottom: 3px solid rgba(153,153,153,0.05);
  }
}
input::-webkit-input-placeholder {
    /* placeholder颜色 */
    color: #999;
    /* placeholder字体大小 */
    font-size: 14px !important;
}
</style>
