<template>
  <div>
    <div v-if="first" class="proving" style="marginTop: 80px">
      <div class="provingBox" @click="phoneProving">
        <div class="provingO proving-title">
          <img src="@/assets/forget/phone_red2.png" alt="">
          <span>手机验证</span>
        </div>
        <div class="provingO">
          通过{{ userInfo.mobile }}接收验证码
        </div>
      </div>
      <div class="provingBox" @click="mailboxProving">
        <div class="provingO proving-title">
          <img src="@/assets/forget/email_red2.png" alt="">
          <span>邮箱验证</span>
        </div>
        <div class="provingO">
          通过{{ userInfo.email }}接收验证码
        </div>
      </div>
    </div>
    <p v-if="first" class="representations" style="margin-top: 95px;">以上都无法找回<span @click="go_counsel"> 人工申诉</span></p>
    <div v-if="second" style="marginTop: 80px">
      <div class="currency" style="font-weight: 600;">为了保护账号安全，需要验证{{ textType }}的有效性</div>
      <div class="currency" style="width: 450px;paddingLeft: 55px">点击以下按钮，将会发送验证码到您的账户绑定的{{ textType }}</div>
      <div class="currency" style="font-weight: 600;margin-bottom: 30px;">{{ authType==1?userInfo.mobile:userInfo.email }}</div>
      <div>
        <span class="next_btn" @click="next(authType)">发送验证码</span>
      </div>
      <p v-if="coexist" class="coexistP" @click="switchMode">切换至使用{{ authType==1?'邮箱':'手机' }}验证</p>
      <p v-if="authType==1" class="currency representations">该绑定手机号不可接收短信，<span @click="go_counsel"> 人工申诉</span></p>
      <p v-if="authType==2" class="currency representations">该绑定邮箱不可接收邮件，<span @click="go_counsel"> 人工申诉</span></p>
    </div>
    <div v-if="third" style="marginTop: 60px">
      <div class="currency">验证码已发送到{{ textType }}</div>
      <div class="currency" style="font-weight: 600;marginBottom: 11px">{{ authType==1?userInfo.mobile:userInfo.email }}</div>
      <div class="enterCode">
        <input v-model="code" class="bor_red" placeholder="请输入验证码">
        <!-- <span class="pray" @click="getcode">获取验证码</span> -->
        <span v-show="iscode" style="cursor: pointer;" class="countDown" @click="getcode">重新获取</span>
        <span v-show="againcode" class="countDown">{{ code_text }}</span>
      </div>
      <p class="ps" :style="err_text?'visibility : visible':''">{{ err_text }}</p>
      <span class="next_btn" @click="sure">确定</span>
    </div>
    <div v-if="nobinding">
      <div class="no-binding">非常抱歉，您的账号暂无任何绑定关系。请通过<span @click="go_counsel">人工申诉</span>，找回密码</div>
    </div>
  </div>
</template>
<script>
import { sendMobile, sendEmail, vertifyMobile, vertifyEmail } from '@/api/forget'
export default {
  props: {
    stepsShow: {
      type: Number,
      default: 1
    },
    type: {
      type: Number,
      default: 1
    },
    userInfo: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      first: true, // 选择类型显示
      second: false, // 发送验证码显示
      third: false, // 输入验证码显示
      nobinding: false, // 无绑定关系
      coexist: false, // 手机号/邮箱都有的情况 true
      authType: '', // 验证类型 1 手机号验证 2 邮箱验证
      textType: '',
      code: '',
      code_text: '60s',
      err_text: '1', // 提示
      s: 60,
      iscode: false,
      againcode: true,
      tong: true
    }
  },
  $route(to, from) {
    console.log(to, '2222')
  },
  created() {

  },
  mounted() {
    console.log(this.userInfo, 'userInfo')
    // 判断手机号/邮箱/用户名验证进入当前页面时，如果邮箱/手机号验证时，直接到第二步发送验证码
    // 用户名进入时，判断该用户名是否绑定手机号/邮箱  二者都绑定进入第一步选择验证类型  只绑定一项直接到第二步
    // 0 手机号  1 用户名  2 邮箱
    switch (this.type) {
      case 0:
        this.first = false
        this.second = true
        this.third = false
        this.authType = 1
        this.textType = '手机'
        break
      case 2:
        this.first = false
        this.second = true
        this.third = false
        this.authType = 2
        this.textType = '邮箱'
        break

      default:
        if (this.userInfo.is_mobile === 1 && this.userInfo.is_email === 1) {
          this.coexist = true
          this.first = true
          this.second = false
          this.third = false
          this.nobinding = false
        } else if (this.userInfo.is_mobile !== 1 && this.userInfo.is_email !== 1) {
          this.first = false
          this.second = false
          this.third = false
          this.nobinding = true
        } else {
          this.first = false
          this.second = true
          this.third = false
          this.nobinding = false
          if (this.userInfo.is_mobile === 1) {
            this.authType = 1
            this.textType = '手机'
          } else {
            this.authType = 2
            this.textType = '邮箱'
          }
        }
        break
    }
  },
  methods: {
    // 选择手机验证
    phoneProving() {
      this.first = false
      this.second = true
      this.third = false
      this.authType = 1
      this.textType = '手机'
    },
    // 选择邮箱验证
    mailboxProving() {
      this.first = false
      this.second = true
      this.third = false
      this.authType = 2
      this.textType = '邮箱'
    },
    switchMode() {
      if (this.authType == 1) {
        this.authType = 2
        this.textType = '邮箱'
      } else {
        this.authType = 1
        this.textType = '手机'
      }
    },
    // 发送验证码
    next() {
      this.err_text = ''
      this.iscode = false
      this.againcode = true
      if (this.authType == 1 && this.tong === true) {
        this.tong = false
        this.sendMobile()
      } else if (this.authType == 2 && this.tong === true) {
        this.tong = false
        this.sendEmail()
      }
    },
    // 发送手机验证码
    sendMobile(lable) {
      sendMobile({ uid: this.userInfo.uid }).then(res => {
        this.first = false
        this.second = false
        this.third = true
        this.tong = true
        if (lable !== 'retransmission') {
          this.gettime()
        }
        // if (res.errNo === 0) {
        //   this.first = false
        //   this.second = false
        //   this.third = true
        //   this.tong = true
        //   if (lable !== 'retransmission') {
        //     this.gettime()
        //   }
        // } else {
        //   // this.err_text = res.msg
        //   this.$message.error(res.msg)
        //   this.tong = true
        // }
      })
    },
    // 发送邮箱验证码
    sendEmail(lable) {
      sendEmail({ uid: this.userInfo.uid }).then(res => {
        this.first = false
        this.second = false
        this.third = true
        this.tong = true
        if (lable !== 'retransmission') {
          this.gettime()
        }
        // if (res.errNo === 0) {
        //   this.first = false
        //   this.second = false
        //   this.third = true
        //   this.tong = true
        //   if (lable !== 'retransmission') {
        //     this.gettime()
        //   }
        // } else {
        //   // this.err_text = res.msg
        //   this.$message.error(res.msg)
        //   this.tong = true
        // }
      })
    },
    // 重发验证码
    getcode() {
      this.iscode = false
      this.againcode = true
      this.gettime()
      if (this.authType == 1) {
        this.sendMobile('retransmission')
      } else {
        this.sendEmail('retransmission')
      }
    },
    sure() {
      if (this.code == '') {
        this.err_text = '请输入验证码'
        return this.err_text
      }
      if (this.authType == 1) {
        this.vertifyMobile()
      } else {
        this.vertifyEmail()
      }
      // this.stepsShow = 3
      // this.$emit('sure', this.stepsShow)
    },
    // 验证手机验证码
    vertifyMobile() {
      const info = {
        uid: this.userInfo.uid,
        code: this.code
      }
      vertifyMobile(info).then(res => {
        if (res.errNo === 0) {
          // this.stepsShow = 3
          this.$emit('sure')
        } else {
          this.err_text = res.msg
        }
      })
    },
    // 验证邮箱验证码
    vertifyEmail() {
      const info = {
        uid: this.userInfo.uid,
        code: this.code
      }
      vertifyEmail(info).then(res => {
        if (res.errNo === 0) {
          // this.stepsShow = 3
          this.$emit('sure')
        } else {
          this.err_text = res.msg
        }
      })
    },
    gettime() {
      this.timer = setInterval(() => {
        // 某些定时器操作
        this.s--
        this.code_text = this.s + 's'
        if (this.s === 0) {
          this.iscode = true
          this.againcode = false
          this.s = 60
          this.code_text = '60s'
          clearInterval(this.timer)
        }
      }, 1000)
    },
    go_counsel() {
      document.getElementsByClassName('chatBtn')[0].click()
    }
  }
}
</script>
<style lang='less' scoped>
*{
  text-align: left;
}
.currency{
  width: 340px;
  margin: 0 auto;
  font-size: 16px;
  color: #666666;
  margin-bottom: 15px;
}
.proving{
  width: 800px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  .provingBox{
    width: 360px;
    height: 150px;
    padding-top: 40px;
    border: 1px solid rgba(153,153,153,1);
    cursor: pointer;
    .provingO{
      width: 280px;
      margin: 0 auto;
      text-align: left;
      font-size: 16px;
      color: #333;
    }
    .proving-title{
      color: #ee2e2e;
      margin-bottom: 20px;
      img{
        width: 20px;
        margin-right: 4px;
        vertical-align: middle;
      }
      span{
        vertical-align: middle;
        font-weight: 600;
      }
    }
  }
}

.next_btn{
  width: 340px;
  height: 50px;
  display: block;
  background: #ee2e2e;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 50px;
  margin: 0 auto ;
  cursor: pointer;
}

.representations{
 margin-top: 66px;
 font-size: 12px;
 text-align: center;
 span{
  font-weight: 600;
  color: #ee2e2e;
  cursor: pointer;
 }
}

.enterCode{
  display: flex;
  width: 340px;
  margin: 0 auto;
  justify-content: space-between;
  .bor_red{
    width: 180px;
    height: 50px;
    position: relative;
    float: left;
    padding-left: 10px;
    border: 1px solid #eee;
    line-height: 50px;
    outline:none;
    font-size: 14px;
    color: #333;
  }
   .countDown{
    display: inline-block;
    width: 136px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border: 1px solid #eee;
    font-size: 14px;
   }
}
.coexistP{
  width: 340px;
  text-align: center;
  font-size: 14px;
  color: #ee2e2e;
  margin: 14px auto;
  cursor: pointer;
  font-weight: 600;
}
.no-binding{
  width: 800px;
  margin: 100px auto 0;
  text-align: center;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 600;
  span{
    color: #ee2e2e;
    cursor: pointer;
  }
}
.ps{
  color: #ee2e2e;
  width: 340px;
  height: 30px;
  line-height: 30px;
  text-align: left;
  padding-left: 10px;
  margin: 0 auto ;
  font-size: 14px;
  visibility: hidden;
}
input::-webkit-input-placeholder {
    /* placeholder颜色 */
    color: #999;
    /* placeholder字体大小 */
    font-size: 14px !important;
}
</style>
