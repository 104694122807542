import request from '@/utils/ucenter_request.js'
// 账号是否存在
export function findAccount(params) {
  return request({
    url: '/user/pwd/findAccount',
    method: 'get',
    params
  })
}
// 发送手机验证码
export function sendMobile(params) {
  return request({
    url: '/user/pwd/sendMobile',
    method: 'get',
    params
  })
}
// 发送邮箱验证码
export function sendEmail(params) {
  return request({
    url: '/user/pwd/sendEmail',
    method: 'get',
    params
  })
}
// 验证手机验证码
export function vertifyMobile(params) {
  return request({
    url: '/user/pwd/vertifyMobile',
    method: 'get',
    params
  })
}
// 验证邮箱验证码
export function vertifyEmail(params) {
  return request({
    url: '/user/pwd/vertifyEmail',
    method: 'get',
    params
  })
}
// 修改密码
export function updatePwd(params) {
  return request({
    url: '/user/pwd/updatePwd',
    method: 'get',
    params
  })
}
