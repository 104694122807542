<!--  忘记密码   -->
<template>
  <div>
    <div class="main">
      <img style="margin: -56px 20px 0 0" src="@/assets/forget/success.png" alt="">
      <p class="main_p">
        <span>恭喜您，重置密码已成功！</span>
        <span>({{ text }}s)后自动返回主页</span>
        <span style="color: #666666;cursor: pointer;margin-top: 20px;" @click="goLogin">去登陆>></span>
      </p>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      text: '',
      s: 3
    }
  },
  created() {

  },
  mounted() {
    this.goZl()
  },
  methods: {
    goLogin() {
      window.open('https://www.zhulong.com/ucenter/login')
    },
    goZl() {
      // setTimeout(() => {
      //   window.location.href = 'https://www.zhulong.com'
      // }, 3000)
      this.timer = setInterval(() => {
        // 某些定时器操作
        this.s--
        this.text = this.s
        if (this.s === 0) {
          clearInterval(this.timer)
          window.location.href = 'https://www.zhulong.com'
          this.s = 3
        }
      }, 1000)
    }
  }
}
</script>
<style lang='less' scoped>
*{
  font-family: PingFangSC-Medium;
  letter-spacing: 0;
}
.main{
  width: 380px;
  height: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  .main_p{
    font-size: 24px;
    color: #EE2E2E;
    font-weight: 500;
    span{
      text-align: center;
      display: block;
    }
  }
}
</style>
