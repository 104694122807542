<!--  忘记密码   -->
<template>
  <div>
    <div class="anzx">
      <p>
        <img src="@/assets/shield.png" alt="">
        账户安全
      </p>
    </div>
    <div class="main">
      <div v-if="stepsShow!=4" class="title">
        通过以下流程可重置您的账户密码
      </div>
      <div v-if="stepsShow!=4" class="steps">
        <p class="stepsP" :class="stepsShow==1?'action': ''">
          <span class="quan">1</span>
          <span class="steps-text">确认账号</span>
        </p>
        <p class="grey">——————————</p>
        <p class="stepsP" :class="stepsShow==2?'action': ''">
          <span class="quan">2</span>
          <span class="steps-text">安全验证</span>
        </p>
        <p class="grey">——————————</p>
        <p class="stepsP" :class="stepsShow==3?'action': ''">
          <span class="quan">3</span>
          <span class="steps-text">重置密码</span>
        </p>
      </div>
      <stepsOne v-if="stepsShow == 1" :steps-show="stepsShow" @next="next"></stepsOne>
      <stepsTwo v-else-if="stepsShow == 2" :steps-show="stepsShow" :user-info="userInfo" :type="type" @sure="sure"></stepsTwo>
      <stepsThree v-else-if="stepsShow == 3" :steps-show="stepsShow" :user-info="userInfo" @alter="alter"></stepsThree>
      <stepsSuccess v-else-if="stepsShow == 4" :steps-show="stepsShow"></stepsSuccess>
      <p v-if="stepsShow == 1" class="representations">以上都无法找回<span @click="go_counsel"> 人工申诉</span></p>
    </div>
  </div>
</template>
<script>
// import '@/styles/weblogin.css'
// import Cookies from 'js-cookie'
import stepsOne from '@/components/login/stepsOne'
import stepsTwo from '@/components/login/stepsTwo'
import stepsThree from '@/components/login/stepsThree'
import stepsSuccess from '@/components/login/stepsSuccess'

export default {
  components: {
    stepsOne, stepsTwo, stepsThree, stepsSuccess
  },
  data() {
    return {
      stepsShow: 1,
      userInfo: {},
      type: '' // 确认账号选择的类型
    }
  },
  created() {
    setTimeout(() => {
      var chatBtn = document.getElementsByClassName('chatBtn')[0]
      chatBtn.style = 'display: block !important;'
    }, 500)
  },
  methods: {
    next(key, type) {
      this.stepsShow = 2
      this.userInfo = key
      this.type = type
    },
    sure() {
      this.stepsShow = 3
    },
    alter() {
      this.stepsShow = 4
    },
    go_counsel() {
      document.getElementsByClassName('chatBtn')[0].click()
    }
  }
}
</script>
<style lang='less' scoped>
*{
  font-family: PingFangSC-Medium;
}
.anzx{
  margin-top: 60px;
  line-height: 100px;
  background: #fff;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  p{
    width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-size: 35px;
    color: #333333;
    font-weight: 600;
    img{
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
  }
}
.main{
  width: 1200px;
  height: 600px;
  margin: 80px auto 0;
  background: #FFFFFF;
}
.title{
  width: 420px;
  // height: 40px;
  margin: 0 auto ;
  padding: 102px 0 30px;
  font-size: 26px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 600;
}
.steps{
  width: 770px;
  display: flex;
  margin: 0 auto 59px;
  justify-content: space-between;
  align-items: center;
  .stepsP{
    display: flex;
    align-items: center;
    .quan{
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin-right: 10px;
      text-align: center;
      background: #999999;
      color: #fff;
      border-radius: 50%;
      font-size: 20px;
    }
    .steps-text{
      height: 30px;
      line-height: 30px;
      font-size: 24px;
      color: #999999;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .action{
    .quan{
      background: #ee2e2e;
    }
    .steps-text{
      color: #EE2E2E;
    }
  }
}
.representations{
 margin-top: 66px;
 font-size: 12px;
 span{
  color: #ee2e2e;
  cursor: pointer;
  font-weight: 600;
 }
}
.back{
  position: absolute;
  margin: 30px 50px;
  cursor: pointer;
}
.grey{
  color: rgba(153,153,153,1);
  width: 120px;
  overflow: hidden;
}

// input::-webkit-input-placeholder {
//     /* placeholder颜色 */
//     color: #ee2e2e !important;
//     /* placeholder字体大小 */
//     font-size: 14px !important;
// }
</style>
