<template>
  <div>
    <div class="currency" style="marginBottom:25px;font-size: 16px;">密码为6-16个字符，不能为纯数字</div>
    <div class="currency" style="marginBottom:30px;">
      <input v-show="is_see" v-model="newpwd" type="password" class="bor_red" placeholder="请输入新密码">
      <input v-show="!is_see" v-model="newpwd" type="text" class="bor_red" placeholder="请输入新密码">
      <span class="eye">
        <img v-show="is_see" src="https://passport.zhulong.com/img/loginimg/bukejian.png" @click="is_see = false">
        <img v-show="!is_see" src="https://passport.zhulong.com//img/loginimg/kejian.png" @click="is_see = true">
      </span>
    </div>
    <div class="currency" style="marginBottom: 0px;">
      <input v-show="is_see2" v-model="reconfirm_newpwd" type="password" class="bor_red" placeholder="请再次确认新密码">
      <input v-show="!is_see2" v-model="reconfirm_newpwd" type="text" class="bor_red" placeholder="请再次确认新密码">
      <span class="eye">
        <img v-show="is_see2" src="https://passport.zhulong.com/img/loginimg/bukejian.png" @click="is_see2 = false">
        <img v-show="!is_see2" src="https://passport.zhulong.com//img/loginimg/kejian.png" @click="is_see2 = true">
      </span>
    </div>
    <div class="ps" :style="text?'visibility : visible':''">{{ text }}</div>
    <span class="next_btn" @click="sure">确认更改</span>
  </div>
</template>
<script>
import { updatePwd } from '@/api/forget'
export default {
  props: {
    stepsShow: {
      type: Number,
      default: 1
    },
    userInfo: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      newpwd: '',
      reconfirm_newpwd: '',
      text: '',
      is_see: true,
      is_see2: true
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    sure() {
      if (this.newpwd === '' || this.reconfirm_newpwd === '') {
        this.text = '请输入新密码'
        return this.text
      }
      if (this.newpwd !== this.reconfirm_newpwd) {
        this.text = '密码不一致，请重新输入'
        return this.text
      }
      var reg = /^[0-9]*$/// 正则表达式
      var regF = new RegExp(reg)
      // regF.test(this.newpwd)
      if (regF.test(this.newpwd)) {
        this.text = '6-16位字符，不能是纯数字'
        return this.text
      }
      if (this.newpwd.length < 6 || this.newpwd.length > 16) {
        this.text = '请输入6-16位字符'
        return this.text
      }
      const info = {
        uid: this.userInfo.uid,
        newpwd: this.newpwd
      }
      this.$emit('alter')
      updatePwd(info).then(res => {
        if (res.errNo === 0) {
          this.text = ''
          this.$emit('alter')
        } else {
          this.text = res.msg
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
.currency{
  margin-bottom: 20px;
}
.bor_red{
  width: 340px;
  height: 50px;
  padding-left: 10px;
  border: 1px solid #eee;
  line-height: 50px;
  outline:none;
  color: #333;
}
.next_btn{
  width: 340px;
  height: 50px;
  display: block;
  background: #ee2e2e;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 50px;
  margin: 0 auto ;
  cursor: pointer;
}
.ps{
  width: 340px;
  height: 30px;
  line-height: 30px;
  color: #ee2e2e;
  font-size: 14px;
  text-align: left;
  padding: 0 0 0 10px;
  visibility: hidden;
  margin: 0 auto ;
}
.eye{
  position: relative;
  margin: 15px 0 0 -15px;
  left: -15px;
  cursor: pointer;
}
input::-webkit-input-safebox-button{
	display: none;/*去掉搜狗浏览器密码软盘*/
}
input::-webkit-input-placeholder {
    /* placeholder颜色 */
    color: #999;
    /* placeholder字体大小 */
    font-size: 14px !important;
}
</style>
